import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ErrorWidgetProps {
    errorCode: string
    title: string
    subTitle?: string
    link?: string
    linkLabel?: string
    action?: VoidFunction
    actionLabel?: string
}

const ErrorWidget: FunctionComponent<ErrorWidgetProps> = (props) => {
    const [t, i18n] = useTranslation('common');
    const { errorCode, title, subTitle, link, linkLabel, action, actionLabel } = props;

    return (
        <>
            <div className="h-56 grid grid-flow-col auto-cols-max gap-4 content-center justify-center items-start">
                <div>
                    <p className="text-5xl font-bold text-indigo-600 text-end pr-4 pb-6 border-r-2">{errorCode}</p>
                </div>
                <div>
                    <p className="text-5xl font-bold text-start">{title}</p>
                    <p className="text-1xl text-start pl-1 pt-2 text-gray-500 text-ellipsis overflow-hidden">{subTitle}</p>
                    {
                        link != null ? 
                            <div className="text-start pt-4">
                                <Link to={link}>
                                    <span className="inline-block text-center bg-gray-600 border border-transparent rounded-md py-1 px-4 font-medium text-white hover:bg-gray-700">
                                        {linkLabel}
                                    </span>
                                </Link>
                            </div> : null 
                    }
                    {
                        link == null && action != null ? 
                            <div className="text-start pt-4">
                                <button onClick={action}>
                                    <span className="inline-block text-center bg-gray-600 border border-transparent rounded-md py-1 px-4 font-medium text-white hover:bg-gray-700">
                                        {actionLabel}
                                    </span>
                                </button>
                            </div> : null 
                    }
                </div>
            </div>                
        </>
    );
}

export default ErrorWidget;