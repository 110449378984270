import { FunctionComponent, useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Thumbs, Scrollbar } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/scrollbar";
import "swiper/css/effect-cards";

import { useTranslation } from "react-i18next";
import { LoadingSubjectData } from "../../../subjects/loadingSubject";
import { useNavigate } from "react-router-dom";
import IProduct from "../../../models/product";
import { ROUTES } from "../../../core/router";
import ErrorWidget from "../../components/ErrorWidget";
import Loader from "../../components/Loader";
import { Logger } from "../../../helpers/logger";
import ChevronLeftIcon from "@heroicons/react/outline/ChevronLeftIcon";
import DetailsWidget from "./Details";
import BaseSubject from "../../../subjects/baseSubject";
import DetailsInfoWidget from "./DetailsInfo";

import Viewer from "react-viewer";
import Fancybox from "../../components/FancyBox";
import ScrollDownWidget from "../../components/ScrollDownWidget";

interface LayoutProps {
  id: string
  loadData: () => Promise<void>
  loadingData: LoadingSubjectData<IProduct | null> | null
  detailSelectedSubject: BaseSubject<number | null> | null
}

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const kThumbCountMax = 10;

  const { id, loadData: loadData, loadingData, detailSelectedSubject } = props;

  const [t, i18n] = useTranslation('common');
  const [tDetails] = useTranslation('details');

  const navigate = useNavigate();

  const [swiperSm, setSwiperSm] = useState(null);
  const [swiperMd, setSwiperMd] = useState(null);
  const [swiperLg, setSwiperLg] = useState(null);

  const [variantIndex, setVariantIndex] = useState(0);
  const [selectedId, setSelectedId] = useState<number | null>(detailSelectedSubject.lastData);
  
  const [viewerVisible, setViewerVisible] = useState(false);
  const colorSection = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
      const dataSubscription = detailSelectedSubject?.stream?.subscribe((_) => {
          setSelectedId(_);
          // TODO: evaluate if we want to show details viewer 
          // setViewerVisibile(true);
        });
    
        return () => {
          dataSubscription?.unsubscribe();
        };
  }, []);

  // store thumbs swiper instance
  // const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // const [thumbsSwiper2, setThumbsSwiper2] = useState(null);

  const isLoading = loadingData?.loading ?? false;
  const hasError = loadingData?.hasError ?? false;
  // const error = loadingData?.error;
  const hasData = !hasError && loadingData?.data != null;
  const product = loadingData?.data;

  Logger.info(product);

  const detailSelected = selectedId != null ? product?.children.find((_) => _.id == selectedId) : null;
  const galleryDetails = detailSelected?.imageGallery;

  const hasGalleryDetails = (galleryDetails?.length ?? 0) > 0;  

  // const navigateBack = (): void => {
  //   navigate(-1);
  // }  

  const navigateHome = (): void => {
    navigate(ROUTES.HOME, { replace: false });
  }

  if (isLoading) {
    return (
      <div className="p-20 max-w-sm w-full mx-auto">
        <Loader colorClass="bg-gray-700" size="10" />
      </div>
    );
  } else if (hasError) {
    return (
      <ErrorWidget
        errorCode={t('labels.error_code_generic')}
        title={t('labels.error_hint')}
        subTitle={t('labels.error_generic')}
        action={(loadData)}
        actionLabel={t('labels.retry')}
      />
    );
  } else if (!hasData) {
    return (
      <ErrorWidget
        errorCode="404"
        title={t('labels.no_data_found')}
        subTitle={tDetails('labels.product_code') + ' - ' + id}
        // link={ROUTES.HOME}
        // linkLabel={t('labels.navigate_home')}
        action={(loadData)}
        actionLabel={t('labels.retry')}        
      />
    );
  }

  const gallery = product.children?.filter((_) => {
    return _.mainImage != null;
  });

  const hasGallery = (gallery?.length ?? 0) > 0;

  const onCarouselChange = (index: number) => {
    if (hasGallery) {
      setVariantIndex(index);

      const item = gallery[index];
      detailSelectedSubject.add(item.id);

      swiperSm?.slideTo(0);
      swiperMd?.slideTo(0);
      swiperLg?.slideTo(0);
    }
  }  

  const fancyBoxOptions = { 
    infinite: false,   
    // disable default click events
    click: false, 
    Image: {
      // removing click to avoid unwanted toggling
      click: false,
      doubleClick: false,  
      Panzoom: {
        zoomFriction: 0.7,
        baseScale: 2, // doesn't work as default zoom at init
        // baseScale: function () {
        //   return 2;
        // },        
        // maxScale: 5, // doesn't work as constant value
        maxScale: function () {
          return 8;
        },
      },
    },    
    on : {
      "*": (event: any, fancybox: any, slide: any) => {
        // console.log(`event: ${event}`);

        //   // console.log(slide?.$el);
        //   // console.log(slide);

        //   // if (event == 'reveal') {
        //   //   const panzoom = slide.Panzoom;
        //   //   if (panzoom) {        
        //   //     console.log(`zoom ...`, slide.Panzoom);
        //   //     panzoom.zoomIn(5);
        //   //   }
        //   // }
      },   
      done : (fancybox: any, slide: any) => {
        const panzoom = slide.Panzoom;
        // console.log(`slide ...`, slide);   
        if (panzoom) {   
          // console.log(`zoom ...`, panzoom);     
          panzoom.zoomIn(2);
        }

        setViewerVisible(true);
      },
      destroy: (fancybox: any) => {
        setViewerVisible(false);
      },
      "Carousel.Panzoom.click": (fancybox: any) => {
        // disable click
        return;

        // const panzoom = fancybox.getSlide().Panzoom;
  
        // // Return if content is not zoomable or is already animating
        // if (!panzoom || panzoom.velocity.scale) {
        //   return;
        // }
  
        // const isZoomedIn = panzoom.content.scale > 1;
  
        // if (isZoomedIn) {
        //   panzoom.zoomTo(1);
        // } else {
        //   panzoom.zoomTo(1.5);
        // }
      },      
    },
  };

  return (
    <>
      <div className="bg-white">
        <div
        // className="pt-1"
        >
          <div className="pb-8 items-end justify-center"> {/*grid grid-flow-col auto-cols-max*/}
            {/* <button
                type="button"
                className="-mt-2 -mr-1 sm:-mr-3 flex p-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
                onClick={navigateHome}>
              <ChevronLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
            </button> */}
            <h1 className="mx-20 text-2xl font-extrabold tracking-normal text-gray-900 sm:text-2xl capitalize">{product.name}</h1>
            {/* <h1 className="mx-20 text-2xl lg:text-left font-extrabold tracking-normal text-gray-900 sm:text-2xl capitalize">{product.title}</h1> */}
            {/* <h1 className="mx-20 text-xl lg:text-left font-normal tracking-normal sm:text-xl">{product.subtitle}</h1> */}
          </div>

          {
            hasGallery ? 
              <div className="mb-6">
                {/* small width device */}
                <div className="block sm:hidden">
                  <Swiper
                    onSwiper={setSwiperSm}
                    className="swiper-css"
                    autoHeight={false}
                    modules={[Navigation, Pagination, Thumbs]}
                    /* Thumb sync: Main Swiper -> pass thumbs swiper instance */
                    // thumbs={{ swiper: thumbsSwiper }}                  
                    // NOTE:! using slidesPerView=2 and centeredSlides=true to get only one thumb active at any time (infact thumbs active are those completly visible inside the swiper)
                    slidesPerView={1}
                    centeredSlides={false}
                    spaceBetween={10}
                    grabCursor={true}
                    speed={100}
                    // freeMode={{
                    //   enabled: true,
                    //   momentum: true,
                    //   momentumVelocityRatio: 1.5,
                    //   sticky: true
                    // }}
                    /* NOTE:! loop expose an issue on clickedIndex */
                    // loop={true}
                    navigation={true}
                    pagination={{
                      enabled: galleryDetails.length > 1 && galleryDetails.length <= kThumbCountMax,
                      clickable: true,
                      bulletClass: "slider-thumb",
                      bulletActiveClass: "slider-thumb-active",
                    }}
                    // onDoubleTap={(swiper) => {
                    //   // Logger.info('Swiper onTap ', swiper.clickedIndex);

                    //   setViewerIndex(swiper.clickedIndex);
                    //   setViewerVisibile(true); 
                    // }}
                  >
                    {
                      galleryDetails.map((_, index) => {
                        return (
                          <SwiperSlide
                            key={`item-${index}-${selectedId}`}
                            aria-label={`slide ${index}`}                         
                          >
                            {({ isActive }) => (
                              <Fancybox options={fancyBoxOptions}>
                                <img src={_.thumbnailUrl} 
                                    // NOTE: use the same id (ex. gallery-n) to navigate images inside fancybox
                                    // data-fancybox="gallery-sm"
                                    data-fancybox={`gallery-sm-${index}`}
                                    data-src={_.imageUrl} 
                                />
                              </Fancybox>
                            )}
                          </SwiperSlide>
                        );
                      })
                    }     
                  </Swiper>
                  <div className="pt-4 pl-4"> 
                          {/* lg:border-r lg:border-gray-200 */}
                    <DetailsInfoWidget 
                      product={product}
                      detailSelectedSubject={detailSelectedSubject}
                    />   
                  </div>                  
                  {/* NOTE: moved navigation arrows outside the swiper (2) - setting relative position on the container to make absolute position of swiper-button work correctly */}
                  <div className="mx-8 relative swiper-css-thumb">
                    <div className="swiper-button-prev swiper-button-prev-thumb"></div>
                    <Swiper
                      className={"swiper-css swiper-css-thumb my-8" + (gallery.length < 4 ? ' swiper-wrapper-center ' : '')}
                      autoHeight={true}
                      modules={[Thumbs, Navigation, Pagination, Scrollbar]}
                      watchSlidesProgress
                      /* Thumb sync: */
                      // onSwiper={setThumbsSwiper}
                      slidesPerView={4}
                      centeredSlides={false}
                      spaceBetween={10}   
                      // scrollbar={{
                      //   hide: true,
                      //   draggable: true
                      // }}
                      scrollbar={false}
                      // NOTE: moved navigation arrows outside the swiper (1)
                      // navigation={true}
                      navigation={{
                        nextEl: '.swiper-button-next-thumb',
                        prevEl: '.swiper-button-prev-thumb'
                      }}
                      // pagination={{
                      //   enabled: gallery.length > 1 && gallery.length <= kThumbCountMax,
                      //   clickable: true,
                      //   bulletClass: "slider-thumb",
                      //   bulletActiveClass: "slider-thumb-active",
                      // }}                    
                      grabCursor={true}
                      speed={200}
                      // freeMode={{
                      //   enabled: true,
                      //   // sticky: true,
                      //   momentum: true
                      // }}                    
                      onTap={(swiper) => {
                        // Logger.info('Swiper onTap ', swiper.clickedIndex);
                        onCarouselChange(swiper.clickedIndex);
                      }}                            
                    >
                      {
                        gallery.map((_, index) => {
                          return (
                            <SwiperSlide      
                              key={`item-${index}`}
                              aria-label={`slide ${index}`}                          
                            >
                              {({ isActive }) => (
                                // <div className={`thumb-${isActive}`}>
                                <div className={`swiper-thumb-selected-${index == variantIndex}`}>
                                  <img src={_.mainImage.thumbnailUrl} />
                                </div>
                              )}
                            </SwiperSlide>
                          );
                        })
                      } 
                    </Swiper>
                    <div className="swiper-button-next swiper-button-next-thumb"></div>
                  </div>                  
                </div>                              
                {/* medium width device */}
                <div className="hidden sm:block md:hidden">
                  <Swiper
                    onSwiper={setSwiperMd}
                    className="swiper-css"
                    autoHeight={false}
                    modules={[Navigation, Pagination, Thumbs]}
                    /* Thumb sync: Main Swiper -> pass thumbs swiper instance */
                    // thumbs={{ swiper: thumbsSwiper }}                  
                    // NOTE:! using slidesPerView=2 and centeredSlides=true to get only one thumb active at any time (infact thumbs active are those completly visible inside the swiper)
                    slidesPerView={1}
                    centeredSlides={false}
                    spaceBetween={10}
                    grabCursor={true}
                    speed={100}
                    // freeMode={{
                    //   enabled: true,
                    //   momentum: true,
                    //   momentumVelocityRatio: 1.5,
                    //   sticky: true
                    // }}
                    /* NOTE:! loop expose an issue on clickedIndex */
                    // loop={true}
                    navigation={true}
                    pagination={{
                      enabled: galleryDetails.length > 1 && galleryDetails.length <= kThumbCountMax,
                      clickable: true,
                      bulletClass: "slider-thumb",
                      bulletActiveClass: "slider-thumb-active",
                    }}
                    // onDoubleTap={(swiper) => {
                    //   // Logger.info('Swiper onTap ', swiper.clickedIndex);

                    //   setViewerIndex(swiper.clickedIndex);
                    //   setViewerVisibile(true); 
                    // }}
                  >
                    {
                      galleryDetails.map((_, index) => {
                        return (
                          <SwiperSlide
                            key={`item-${index}-${selectedId}`}
                            aria-label={`slide ${index}`}                         
                          >
                            {({ isActive }) => (
                              <Fancybox options={fancyBoxOptions}>
                                <img src={_.thumbnailUrl} 
                                    // NOTE: use the same id (ex. gallery-n) to navigate images inside fancybox
                                    // data-fancybox="gallery-md"
                                    data-fancybox={`gallery-md-${index}`}
                                    data-src={_.imageUrl} 
                                />
                              </Fancybox>
                            )}
                          </SwiperSlide>
                        );
                      })
                    }     
                  </Swiper>
                  <div className="pt-4 pl-4"> 
                          {/* lg:border-r lg:border-gray-200 */}
                    <DetailsInfoWidget 
                      product={product}
                      detailSelectedSubject={detailSelectedSubject}
                    />   
                  </div>                  
                  {/* NOTE: moved navigation arrows outside the swiper (2) - setting relative position on the container to make absolute position of swiper-button work correctly */}
                  <div className="mx-8 relative swiper-css-thumb">
                    <div className="swiper-button-prev swiper-button-prev-thumb"></div>
                    <Swiper
                      className={"swiper-css swiper-css-thumb my-8" + (gallery.length < 6 ? ' swiper-wrapper-center ' : '')}   
                      autoHeight={true}
                      modules={[Thumbs, Navigation, Pagination, Scrollbar]}
                      watchSlidesProgress
                      /* Thumb sync: */
                      // onSwiper={setThumbsSwiper}
                      slidesPerView={6}
                      centeredSlides={false}
                      spaceBetween={10}   
                      // scrollbar={{
                      //   hide: true,
                      //   draggable: true
                      // }}
                      scrollbar={false}
                      // NOTE: moved navigation arrows outside the swiper (1)
                      // navigation={true}
                      navigation={{
                        nextEl: '.swiper-button-next-thumb',
                        prevEl: '.swiper-button-prev-thumb'
                      }}
                      // pagination={{
                      //   enabled: gallery.length > 1 && gallery.length <= kThumbCountMax,
                      //   clickable: true,
                      //   bulletClass: "slider-thumb",
                      //   bulletActiveClass: "slider-thumb-active",
                      // }}                    
                      grabCursor={true}
                      speed={200}
                      // freeMode={{
                      //   enabled: true,
                      //   // sticky: true,
                      //   momentum: true
                      // }}                    
                      onTap={(swiper) => {
                        // Logger.info('Swiper onTap ', swiper.clickedIndex);
                        onCarouselChange(swiper.clickedIndex);
                      }}                            
                    >
                      {
                        gallery.map((_, index) => {
                          return (
                            <SwiperSlide      
                              key={`item-${index}`}
                              aria-label={`slide ${index}`}                          
                            >
                              {({ isActive }) => (
                                // <div className={`thumb-${isActive}`}>
                                <div className={`swiper-thumb-selected-${index == variantIndex}`}>
                                  <img src={_.mainImage.thumbnailUrl} />
                                </div>
                              )}
                            </SwiperSlide>
                          );
                        })
                      } 
                    </Swiper>
                    <div className="swiper-button-next swiper-button-next-thumb"></div>
                  </div>                  
                </div>               
                {/* large width device */}
                <div className="hidden md:block">
                  <Swiper
                    onSwiper={setSwiperLg}
                    className="swiper-css px-8"
                    autoHeight={false}
                    modules={[Navigation, Pagination, Thumbs, Scrollbar]} 
                    /* Thumb sync: Main Swiper -> pass thumbs swiper instance */
                    // thumbs={{ swiper: thumbsSwiper }}  
                    // NOTE:! using slidesPerView=2 and centeredSlides=true to get only one thumb active at any time (infact thumbs active are those completly visible inside the swiper)
                    slidesPerView={4}
                    centeredSlides={false}
                    spaceBetween={10}
                    grabCursor={true}
                    speed={200}
                    // freeMode={{
                    //   enabled: true,
                    //   momentum: true,
                    //   momentumVelocityRatio: 1.5,
                    //   sticky: true
                    // }}
                    /* NOTE:! loop expose an issue on clickedIndex */
                    // loop={true}
                    navigation={true}
                    pagination={{
                      enabled: true,
                      clickable: true,
                      bulletClass: "slider-thumb",
                      bulletActiveClass: "slider-thumb-active",
                    }}              
                    scrollbar={{
                      hide: true,
                      draggable: true
                    }}                  
                    // onDoubleTap={(swiper) => {
                    //   // Logger.info('Swiper onTap ', swiper.clickedIndex);
                    //   // onCarouselChange(swiper.clickedIndex);

                    //   setViewerIndex(swiper.clickedIndex);
                    //   setViewerVisibile(true); 
                    // }}
                  >
                    {
                      galleryDetails.map((_, index) => {
                        return (
                          <SwiperSlide
                            key={`item-${index}-${selectedId}`}
                            aria-label={`slide ${index}`}                         
                          >
                            {({ isActive }) => (
                              <Fancybox options={fancyBoxOptions}>
                                <img src={_.thumbnailUrl} 
                                    // NOTE: use the same id (ex. gallery-n) to navigate images inside fancybox
                                    // data-fancybox="gallery-lg"
                                    data-fancybox={`gallery-lg-${index}`}
                                    data-src={_.imageUrl} 
                                />
                              </Fancybox>
                            )}
                          </SwiperSlide>
                        );
                      })
                    }     
                  </Swiper>
                  <div className="pt-4 pl-4"> 
                          {/* lg:border-r lg:border-gray-200 */}
                    <DetailsInfoWidget 
                      product={product}
                      detailSelectedSubject={detailSelectedSubject}
                    />   
                  </div>
                  {/* NOTE: moved navigation arrows outside the swiper (2) - setting relative position on the container to make absolute position of swiper-button work correctly */}
                  <div className="mx-8 relative swiper-css-thumb">
                    <div className="swiper-button-prev swiper-button-prev-thumb"></div>
                    <Swiper
                      className={"swiper-css swiper-css-thumb my-8" + (gallery.length < 8 ? ' swiper-wrapper-center ' : '')}     
                      autoHeight={true}
                      modules={[Thumbs, Navigation, Pagination, Scrollbar]}
                      watchSlidesProgress
                      /* Thumb sync: */
                      // onSwiper={setThumbsSwiper}
                      slidesPerView={8}
                      centeredSlides={false}
                      spaceBetween={10}   
                      scrollbar={{
                        hide: true,
                        draggable: true
                      }}
                      // NOTE: moved navigation arrows outside the swiper (1)
                      // navigation={true}
                      navigation={{
                        nextEl: '.swiper-button-next-thumb',
                        prevEl: '.swiper-button-prev-thumb'
                      }}
                      // pagination={{
                      //   enabled: gallery.length > 1 && gallery.length <= kThumbCountMax,
                      //   clickable: true,
                      //   bulletClass: "slider-thumb",
                      //   bulletActiveClass: "slider-thumb-active",
                      // }}                    
                      grabCursor={true}
                      speed={200}
                      // freeMode={{
                      //   enabled: true,
                      //   // sticky: true,
                      //   momentum: true
                      // }}                    
                      onTap={(swiper) => {
                        // Logger.info('Swiper onTap ', swiper.clickedIndex);
                        onCarouselChange(swiper.clickedIndex);
                      }}                            
                    >
                      {
                        gallery.map((_, index) => {
                          return (
                            <SwiperSlide      
                              key={`item-${index}`}
                              aria-label={`slide ${index}`}                          
                            >
                              {({ isActive }) => (
                                // <div className={`thumb-${isActive}`}>
                                <div className={`swiper-thumb-selected-${index == variantIndex}`}>
                                  <img src={_.mainImage.thumbnailUrl} />
                                </div>
                              )}
                            </SwiperSlide>
                          );
                        })
                      } 
                    </Swiper>
                    <div className="swiper-button-next swiper-button-next-thumb"></div>
                  </div>                  
                </div>                  
              </div>              
              : null
          }   

          <div className="px-8" ref={colorSection}> 
                          {/* lg:border-r lg:border-gray-200 */}
            {
              hasGallery && gallery.length > 1 ?
                <h1 className="mt-1 text-sm sm:text-sm text-center font-bold tracking-tight text-gray-900">{gallery.length}&nbsp;{tDetails('labels.colors_available').toLowerCase()}</h1>
                : null
            }
            <div className="pt-8 mt-6 space-y-6">
              <p className="text-lg text-center text-gray-900 leading-snug" dangerouslySetInnerHTML={{ __html: product.description }}></p>
            </div>

            <div className="flex-shrink-0 grid place-content-center">
              <img className="inline h-40 logo"
                  src="/hero_pos.png"
                  alt="logo" />
            </div>            

          </div>

        </div>
        {
          !viewerVisible ? 
            <ScrollDownWidget 
                elementTarget={colorSection.current}
                visibilityCallback={ (scrollY) => {
                  // console.log('scrollY: ', scrollY);
                  return scrollY < 300;
                } }
            /> : null 
        }
      </div>
    </>
  );
}

export default Layout; 