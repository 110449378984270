import { RouteObject } from "react-router-dom";
import Layout from "../ui/components/Layout";
import Home from "../ui/pages/home/Home";
import NotFound from "../ui/pages/notFound/NotFound";
import Product from "../ui/pages/product/Product";

export abstract class ROUTES {
  public static readonly ROOT = '/';
  public static readonly HOME = '/';
  public static readonly PRODUCT = '/product/:id';
  public static readonly PRODUCT_PREFIX = '/product/';
}

const routes: RouteObject[] = [
  { path: ROUTES.ROOT, element: <Layout />, children: [
    { index: true, element: <Home /> },
    { path: ROUTES.PRODUCT, element: <Product /> },
    { path: '*', element: <NotFound /> }  
  ] },
];

export default routes;