import axios, { AxiosError, AxiosInstance } from "axios";
import { IApiProvider } from "./_interfaces";
import { ApiRepoHttp } from "./apiRepoHttp";

export class ApiProviderHttp implements IApiProvider {
    static readonly HTTP_HEADER_AUTH: string = 'Authorization';

    private static _theProvider: ApiProviderHttp;

    readonly _apiRepository: ApiRepoHttp<IApiProvider>;

    private _endpointApi! : AxiosInstance;

    public get endpointApi() : AxiosInstance
    {
        return this._endpointApi;
    }

    protected set endpointApi(v : AxiosInstance) {
        this._endpointApi = v;
    }

    protected constructor() {
        this._apiRepository = new ApiRepoHttp(this);

        this.endpointApi = axios.create({
            baseURL: process.env.REACT_APP_HOST_API,
            timeout: 20000,
            // headers: {'X-Custom-Header': 'foobar'}
        });

        // this.endpointApi.interceptors.request.use(function (config) {
        //     // NOTE: token is handled inside request body for now
        //     // TODO:! evaluate to move token to headers

        //     if (!config.url?.endsWith(process.env.REACT_APP_ENDPOINT_TOKEN!)) {
        //         const token = serviceAuth.isAuthenticated ? serviceAuth.getToken() : null;
        //         if (token != null) {
        //             // config.headers ||= {}
        //             // config.headers[ApiProviderHttp.HTTP_HEADER_AUTH] = `Bearer ${token}`

        //             config.data ||= {}
        //             const configData = config.data;

        //             if (configData != null) {
        //                 // check is json object
        //                 const objectConstructor = ({}).constructor;
        //                 if (configData.constructor === objectConstructor) {
        //                     configData.BearerToken = token;
        //                 }
        //             }
        //         }
        //     }

        //     return config;
        // }, function (error: AxiosError) {
        //     // Do something with request error
        //     return Promise.reject(error);
        // });      

        // this.endpointApi.interceptors.response.use(function (response) {
        //     // Any status code that lie within the range of 2xx cause this function to trigger
        //     // Do something with response data
        //     return response;
        //   }, function (error: AxiosError) {
        //     // Any status codes that falls outside the range of 2xx cause this function to trigger
        //     // Do something with response error

        //     const response = error?.response;
        //     const responsePayload = response?.data as IApiResponse;

        //     const httpStatus = response?.status;
        //     // const invalidToken = responsePayload?.Results?.ErrorString?.toLocaleLowerCase() === 'invalid bearer token';

        //     if (httpStatus == 401 /*||
        //         // TODO:! evaluate status 400
        //         (httpStatus == 400 && invalidToken)*/) {
        //         const serviceAuth = AuthService.serviceFactory();
        //         serviceAuth.signOut();
        //     }

        //     return Promise.reject(error);
        //   });        
    }

    public static providerFactory(): ApiProviderHttp {
        return this._theProvider ??= new ApiProviderHttp;
    }
}