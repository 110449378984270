import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface LoaderProps {
    colorClass?: string
    size?: string
}
 
const Loader: FunctionComponent<LoaderProps> = (props) => {
    const { colorClass, size } = props;
    const [t, i18n] = useTranslation('common');
    
    return (  
        // <div className="animate-pulse flex space-x-4">
        //     <div className={`rounded-full ${colorClass} h-${size} w-${size}`}></div>
        //     <div className="flex-1 space-y-6 py-1">
        //     <div className={`h-2 ${colorClass} rounded`}></div>
        //     <div className="space-y-3">
        //         <div className="grid grid-cols-3 gap-4">
        //         <div className={`h-2 ${colorClass} rounded col-span-2`}></div>
        //         <div className={`h-2 ${colorClass} rounded col-span-1`}></div>
        //         </div>
        //         <div className={`h-2 ${colorClass} rounded`}></div>
        //     </div>
        //     </div>
        // </div>
        <div className="spinner">
            {t('labels.loading').toLowerCase()}
            <div className="spinner-sector spinner-sector-black"></div>
            <div className="spinner-sector spinner-sector-gray"></div>
            <div className="spinner-sector spinner-sector-gray-2"></div>
        </div>
    );
}
 
export default Loader;