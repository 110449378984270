import { IApiRepo as IApiRepo, IApiProvider } from "./_interfaces"
import IApiResponse from "../../models/apiResponse";
import IProduct from "../../models/product";

export class ApiRepoHttp<T extends IApiProvider> implements IApiRepo {
    readonly apiProvider: T;

    constructor(apiProvider: T) {
        this.apiProvider = apiProvider;
    }
    
    public async getProduct(id: string, lang?: string): Promise<IProduct | null> {
        const buildVersion = process.env.REACT_APP_BUILD ?? '-';
        const endpoint = process.env.REACT_APP_ENDPOINT_PRODUCT + '/' + encodeURIComponent(id.trim().toUpperCase()) + '?' + (lang != null ? 'l=' + lang + '&' : '') + ('b=' + buildVersion);
        const response = await this.apiProvider.endpointApi.get(endpoint!);

        if (response.status == 200) {
            const responseContent: IApiResponse<IProduct> = response.data;
            if (responseContent.result.success) {
                return responseContent.data ?? null;
            }
        }

        return null;
    }
}
