import { FunctionComponent, useEffect, useState } from "react";

interface ScrollDownWidgetProps {
    elementTarget?: HTMLDivElement | null
    visibilityCallback: (scrollY: number) => boolean
}
 
const ScrollDownWidget: FunctionComponent<ScrollDownWidgetProps> = (props) => {
    const { elementTarget, visibilityCallback } = props;
    
    const [widgetVisible, setWidgetVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", evalVisibility);

        evalVisibility();

        return () => {
            window.removeEventListener("scroll", evalVisibility);
        };
    }, []);

    const evalVisibility = () => {
        setWidgetVisible(visibilityCallback(window.scrollY));
    };

    const scrollTo = () => {
        window.scrollTo({
            top: (elementTarget ?? document.documentElement).scrollHeight,
            behavior: 'smooth',
        });
    };

    return ( 
        widgetVisible ?
            <div className="scroll-down-widget" onClick={scrollTo} />
            : null
    );
}
 
export default ScrollDownWidget;