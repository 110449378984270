import { shareLatest } from "@react-rxjs/core";
import { distinct, Observable, Subscriber } from "rxjs";
import { Logger } from "../helpers/logger";

class BaseSubject<T> {
    private _dataLast: T | null = null

    private _hasError = false
    private _error: any

    public stream?: Observable<T>;
    protected sink?: Subscriber<T>;

    constructor (data: T | null) {
        this._dataLast = data;
        this._hasError = false;
        this._error = null;

        this.stream = new Observable<T>((obs) => {
            this.sink = obs;
            if (data != null) {
                this.sink?.next(data);
            }
        }).pipe(shareLatest());
    }

    public add(data: T | null) : void {
        this._dataLast = data;

        this._hasError = false;
        this._error = null;

        this.sink?.next(data);
    }

    public get lastData(): T | null {
        return this._dataLast;
    }

    public addError(error: any) : void {
        this._hasError = true;
        this._error = error;

        // HACK: an error will complete the observable
        // this.sink?.error(error);
        this.sink?.next(this._dataLast);
        // ]
    }

    public get hasError(): boolean {
        return this._hasError;
    }    

    public get getError(): any {
        return this._error;
    }

    public clear(): void {
        this.add(null);
    }

    public close(): void {       
        this._hasError = false;
        this._error = null;

        this.sink?.complete();
    }

    public isClosed(): boolean {
        return this.sink?.closed ?? true;
    }
}

export default BaseSubject