import { ApiRepoFactory } from "../../api/apiRepoFactory";
import { IApiFilterArgsMarker } from "../_interfaces";
import BlocApiSingle from "../blocApiSingle";
import { ApiLoadPayload, BlocApiCachePolicy } from "../_common";
import IProduct from "../../../models/product";

// NOTE: extended filters on data
export class ApiFilterArgsSingle implements IApiFilterArgsMarker {

}

export class ProductBloc extends BlocApiSingle<IProduct, ApiFilterArgsSingle> {
    protected id: string

    public static blocFactory(id: string): ProductBloc {
        const resolveId = (_: IProduct) => `${_.id}`;
        const bookingReload = false;
        const subscribeAuth = true;

        const bloc = new ProductBloc(
            resolveId,
            BlocApiCachePolicy.Ignore,
            'ProductBloc',
            bookingReload,
            subscribeAuth
        );

        bloc.id = id;

        return bloc;
    }

    protected override async loadInternal(payload: ApiLoadPayload, params: ApiFilterArgsSingle): Promise<IProduct> {
        // TODO:! testing delay
        // await new Promise((res) => setTimeout(res, 2000));

        const repo = ApiRepoFactory.getRepo();
        return await repo.getProduct(this.id, payload.lang);   
    }
}