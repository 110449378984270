import { Fragment, FunctionComponent } from "react";


import { useTranslation } from "react-i18next";
import { LoadingSubjectData } from "../../../subjects/loadingSubject";

import LayoutScaffold from "../../components/LayoutScaffold";
import Layout from "./Layout";

import IProduct from "../../../models/product";
import BaseSubject from "../../../subjects/baseSubject";

interface ScaffoldProps {
  id: string
  loadData: () => Promise<void>
  loadingData: LoadingSubjectData<IProduct | null> | null
  detailSelectedSubject: BaseSubject<number | null> | null
}

const Scaffold: FunctionComponent<ScaffoldProps> = (props) => {
  
  const { id, loadData: loadData, loadingData, detailSelectedSubject } = props;

  const [t, i18n] = useTranslation('common');
  const [tDetails] = useTranslation('details');

  return (
    <LayoutScaffold
      canLogout={true}
      title={tDetails('labels.title')}
      subtitle={id}
      actions= {
        <></>
      }
      navigation={
       [{
          label: t('menu.product'),
          // route: null
       }] 
      }
      >
        <Layout 
          id={id}
          loadData={loadData}
          loadingData={loadingData}
          detailSelectedSubject={detailSelectedSubject}
        />
    </LayoutScaffold>    
  );
}

export default Scaffold; 