import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import common_en from "../i18n/en/common.json";
import common_it from "../i18n/it/common.json";
import login_en from "../i18n/en/login.json";
import login_it from "../i18n/it/login.json";
import home_en from "../i18n/en/home.json";
import home_it from "../i18n/it/home.json";
import details_en from "../i18n/en/details.json";
import details_it from "../i18n/it/details.json";

abstract class i18nHelper {
    public static initialize () {
        i18next.use(LanguageDetector)
               .use(initReactI18next) // bind react-i18next to the instance
               .init({
            interpolation: { escapeValue: false },  // React already does escaping
            // lng: 'en',                              // language to use
            resources: {
                en: {
                    common: common_en,
                    login: login_en,
                    home: home_en,
                    details: details_en
                },
                it: {
                    common: common_it,
                    login: login_it,
                    home: home_it,
                    details: details_it
                },
            },
            fallbackLng: { 
              // 'de-CH': ['fr', 'it'], //French and Italian are also spoken in Switzerland
              // 'zh-Hant': ['zh-Hans', 'en'],
              // 'es': ['fr'],
              'default': ['en']
            }  
          });
    }
}

export default i18nHelper