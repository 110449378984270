import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../core/router"
import ErrorWidget from "../../components/ErrorWidget";

interface NotFoundProps {

}

const NotFound: FunctionComponent<NotFoundProps> = () => {
    const [t, i18n] = useTranslation('common');

    return (
        <ErrorWidget 
            errorCode="404"
            title={t('labels.not_found')}
            subTitle={t('labels.not_found_warning')}
            link={ROUTES.HOME}
            linkLabel={t('labels.navigate_home')}
        />      
    );
}

export default NotFound;