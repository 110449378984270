import React, { FunctionComponent, useEffect } from "react";

// @ts-ignore
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

interface FancyboxProps {
    delegate?: string
    options?: any
    children: React.ReactNode | React.ReactNode[];
  }
  

const Fancybox: FunctionComponent<FancyboxProps> = (props) => {
  const delegate = props.delegate || "[data-fancybox]";

  useEffect(() => {
    const opts = props.options || {};

    NativeFancybox.bind(delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, []);

  return <>{props.children}</>;
}

export default Fancybox;