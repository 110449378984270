import { FunctionComponent, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../core/router";
import { Logger } from "../../../helpers/logger";

interface LayoutProps {
}

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const [t, i18n] = useTranslation('common');
  const [tHome] = useTranslation('home');

  const searchTextBox = useRef<HTMLInputElement>();
  const navigate = useNavigate();

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    const productId = searchTextBox.current.value;
    Logger.debug(productId);

    if (productId != null && productId.trim() != '') {
      navigateProduct(productId);
    }
  }

  const navigateProduct = (id: string) => {
    const familyCode = id.trim().toUpperCase();

    // if (!familyCode.startsWith('FM-')) {
    //   familyCode = 'FM-' + familyCode;
    // }

    const route = `${ROUTES.PRODUCT_PREFIX}${familyCode}`;

    navigate(route, { 
        replace: false
        // state: params
    });                            
}    

  return (
    <div className="grid grid-flow-row auto-rows-max gap-y-10 items-center justify-center">
        <div className="px-8 grid grid-flow-row sm:grid-flow-col sm:auto-cols-max gap-x-8 gap-y-8 items-center justify-center">
          <div className="sm:max-w-lg sm:pl-12">
            <h1 className="text-3xl sm:text-3xl text-center font-bold text-black">
              {tHome('labels.title')}
            </h1>
            <p className="mt-4 text-xl text-center text-gray-500 tracking-tight">
              {tHome('labels.description')}
            </p>
          </div>
          <div className="flex-shrink-0">
              <img className="inline h-64 sm:h-52 logo"
                  src="/hero_pos.png"
                  alt="logo" />
          </div>
        </div>
        <div className="px-8">
          <form onSubmit={search}>
              <div className="w-full mb-4 input-group relative flex flex-wrap items-stretch"> 
                <input ref={searchTextBox} type="search" className="form-control relative m-0 px-3 py-1.5 flex-auto min-w-0 block text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-l-4 border-gray-300 rounded-l transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-gray-600 focus:outline-none" 
                      placeholder={tHome('labels.search_placeholder')} aria-label={tHome('labels.search')} aria-describedby="button-addon2" />
                <button
                    type="submit"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="inline-block px-6 py-2.5 bg-black text-white font-medium text-xs leading-tight uppercase rounded-r shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out"
                  >{tHome('labels.go')}</button>
              </div>
            </form>
          </div>
    </div>
  );
}

export default Layout; 