import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Logger } from "../../../helpers/logger";
import IProduct from "../../../models/product";
import BaseSubject from "../../../subjects/baseSubject";

interface DetailsInfoWidgetProps {
    product: IProduct | null
    detailSelectedSubject: BaseSubject<number | null> | null
}
 
const DetailsInfoWidget: FunctionComponent<DetailsInfoWidgetProps> = (props) => {
    const { product, detailSelectedSubject } = props;

    const [selectedId, setSelectedId] = useState<number | null>(detailSelectedSubject.lastData);

    useEffect(() => {
        const dataSubscription = detailSelectedSubject?.stream?.subscribe((_) => {
            setSelectedId(_);
            // TODO: evaluate if we want to show details viewer 
            // setViewerVisibile(true);
          });
      
          return () => {
            dataSubscription?.unsubscribe();
          };
    }, []);

    const detailSelected = selectedId != null ? product?.children.find((_) => _.id == selectedId) : null;

    return ( 
        <div>
          {
            detailSelected?.primaryColor != null ? 
              <div>
                <span className="text-sm text-center font-bold tracking-normal text-gray-900 capitalize">{detailSelected.primaryColor.toLowerCase()}</span>
                {
                  detailSelected?.secondaryColor != null ? 
                  <span className="text-sm text-center font-bold tracking-normal text-gray-900 capitalize">&nbsp;/&nbsp;{detailSelected.secondaryColor.toLowerCase()}</span>
                  : null
                }
              </div>
              : null
          }
          {
            detailSelected?.itemId != null ? 
              <div className="mt-2">
                <span className="text-sm text-center font-normal tracking-normal text-gray-900 capitalize">{detailSelected.itemId}</span>
              </div>
              : null
          }          
        </div>
    );
}
 
export default DetailsInfoWidget;