import { IApiFilterArgsMarker } from "./_interfaces";

export enum BlocApiLoadOp { LoadAll, LoadMore, Reply, ReplyAndCache }
export enum BlocApiCachePolicy { Ignore, CacheThenLoad, OnlyCacheIfAny }

export class BlocApiLoadParams<P extends IApiFilterArgsMarker> {
    constructor(
        public readonly loadOp: BlocApiLoadOp,
        public readonly reload?: boolean,
        public readonly userAction?: boolean,
        public readonly filter?: string,
        public readonly filterArgs?: P,
        public readonly lang?: string
    ) { }
}

export class ApiLoadPayload {
    constructor(
        public readonly lang?: string,
        public readonly filter?: string,
        public readonly skip?: number,
        public readonly take?: number,
        public readonly reload?: boolean,
        // public readonly anonId?: string,
        // public readonly reqId?: string
    ) { }
}

export abstract class BlocApiHelper {
    static readonly kPendingLoadDelay = 100;
    static readonly kCacheThenLoadDelay = 750;

    static buildReqId(): string {
        return new Date().toISOString()
            .replaceAll(':', '')
            .replaceAll('-', '')
            .replaceAll('.', '');
    }
}