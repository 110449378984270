import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import 'tw-elements';

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import './index.css';
import App from './core/App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import i18nHelper from './helpers/i18n';

i18nHelper.initialize();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // React Component is rendering twice because of Strict Mode
  // https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
  // In Strict Mode, React double invokes render methods in order to flush out potential side effects. 
  // During the second render, React used to automatically silence console methods (ex. console.log() and console.warn()) to reduce the noise of multiple repeat logs. 
  // However, this ended up causing a lot of confusion for developers during debugging, so we are changing this behavior.  
  
  // <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App/>
      </I18nextProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
