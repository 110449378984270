import { distinct, Observable, Subscriber, Subscription } from "rxjs";
import { Logger } from "../../helpers/logger";
import { IDto } from "../../models/_interfaces";
import LoadingSubject, { LoadingSubjectData } from "../../subjects/loadingSubject";
import { ApiRepoFactory } from "../api/apiRepoFactory";
import { IApiRepo } from "../api/_interfaces";
// import { IAuthService } from "../auth/authI";
// import { AuthService } from "../auth/authService";
import { ApiLoadPayload, BlocApiCachePolicy, BlocApiHelper, BlocApiLoadOp, BlocApiLoadParams } from "./_common";
import { IApiFilterArgsMarker, IBloc } from "./_interfaces";

abstract class BlocApiSingle<T extends IDto, P extends IApiFilterArgsMarker> implements IBloc {
    protected apiRepository?: IApiRepo

    // protected _authService?: IAuthService
    // protected _authSubscription?: Subscription

    protected _filter?: string
    public get filter(): string | undefined { return this._filter; }

    protected _filterArgs?: P
    public get filterArgs(): P | undefined { return this._filterArgs; }

    protected _loadReqId?: string

    protected _loadedData: T | null = null;
    public get loadedData(): T | null { return this._loadedData; }

    protected _loadDone = false
    public get loadDone(): boolean { return this._loadDone; }

    protected _loadingOp?: BlocApiLoadOp
    public get loadingOp(): BlocApiLoadOp | undefined { return this._loadingOp; }

    public get isLoading(): boolean { return this._loadingOp != null; }

    protected _userAction?: boolean

    protected _loadedCache = false;
    protected _loadedSubject?: LoadingSubject<T>
    public loadedStream?: Observable<LoadingSubjectData<T>>

    protected _loadingCommandStream?: Observable<BlocApiLoadParams<P>>
    protected _loadingCommandSubcription?: Subscription
    public loadingCommandSink?: Subscriber<BlocApiLoadParams<P>>

    protected _loadingPending?: BlocApiLoadParams<P>;
    protected _loadingCanExecute = true;

    constructor(
        protected readonly resolveId: (dto: T) => string,
        public readonly cachePolicy: BlocApiCachePolicy,
        public readonly logTag?: string,
        protected bookingReload = false,
        public readonly subscribeAuth = false
    ) {
        // assert(this.pageSize == null || pageSize! > 0);
        this._setup();
    }

    private _setup(): void {
        this.apiRepository = ApiRepoFactory.getRepo();

        this._setupLoadCommand();

        if (this.subscribeAuth) {
            this._setupAuth();
        }
    }

    private _setupLoadCommand(): void {
        this._loadedSubject = new LoadingSubject<T>(null, false);
        this.loadedStream = this._loadedSubject.stream;

        this._loadingCommandStream = new Observable<BlocApiLoadParams<P>>((obs) => {
            this.loadingCommandSink = obs;
        }).pipe(distinct());

        this._loadingCommandSubcription = this._loadingCommandStream.subscribe(async (req) => {
            if (this._loadingCanExecute) {
                this._loadingPending = undefined;

                try {
                    this._loadingCanExecute = false;
                    this._loadedSubject.loading(true);
                    const data = await this._loadingCommandExecute(req);
                    this._loadedSubject.add(data, false);
                } catch (err) {
                    this._loadedSubject.addError(err, false);
                    throw err;
                } finally {
                    this._loadingCanExecute = true;
                }
            } else {
                // enqueue the new request
                this._loadingPending = req;
            }
        });
    }

    protected _setupAuth(): void {
        // this._authService = AuthService.serviceFactory();

        // this._authSubscription = this._authService.streamAuthenticated?.subscribe((_) => {
        //     const authenticated = _.data ?? false;

        //     if (authenticated) {
        //       this.reload();
        //     } else {
        //       this._clearData();
        //     }
        // });
    }

    public dispose(): void {
        this._loadingCommandSubcription?.unsubscribe();
        this.loadingCommandSink?.complete();

        this._loadedSubject?.close();

        // this._authSubscription?.unsubscribe();
    }

    public setBookingReload(val = true): void {
        this.bookingReload = val;
    }

    protected async _loadingCommandExecute(req: BlocApiLoadParams<P>): Promise<T | null> {
        //region reply
        if (req.loadOp == BlocApiLoadOp.Reply || req.loadOp == BlocApiLoadOp.ReplyAndCache) {
            this.whenLoadComplete(this._loadedData);

            if (req.loadOp == BlocApiLoadOp.ReplyAndCache) {
                const skip: number | null = null;
                const take: number | null = null;
                const reload = true;

                const payloadReply = new ApiLoadPayload(req.lang, req.filter, skip, take, reload);
                const cacheable = await this.shouldWriteCache(this._loadedData, payloadReply, req);

                if (cacheable) {
                    try {
                        await this.writeCache(this._loadedData, payloadReply, req);
                    } catch (e) {
                        Logger.error(e);
                        // throw e;
                    }
                }
            }

            return this._loadedData;
        }

        //endregion

        const filterChanged = req.filter != this._filter || req.filterArgs != this._filterArgs;
        const reload = req.reload == true || this.bookingReload;

        this._userAction = req.userAction;

        const loadRequired = reload || !this._loadDone || filterChanged || this._loadedCache;

        if (!loadRequired) {
            this.whenLoadComplete(this._loadedData);
            return this._loadedData;
        }

        this.bookingReload = false;

        //    if (reload) {
        //      this._clearData();
        //    }

        if (filterChanged) {
            this._clearData();
        }

        const reqId = this._loadReqId = BlocApiHelper.buildReqId();

        const filter = req.loadOp == BlocApiLoadOp.LoadAll ? req.filter : this._filter;

        const filterArgs =
            (req.loadOp == BlocApiLoadOp.LoadAll || filterChanged) ? req.filterArgs : this._filterArgs;

        const skip: number | null = null;
        const take: number | null = null;

        const payload = new ApiLoadPayload(req.lang, filter, skip, take, reload);
       
        //region cache

        if (!this._loadDone && !this._loadedCache) {
            const cacheable = await this.shouldReadCache(payload, req);

            if (cacheable) {
                try {
                    const cacheData = await this.readCache(payload, req);
                    if (cacheData != null) {
                        this._loadedCache = true;
                        this._filter = req.filter;
                        this._filterArgs = req.filterArgs;

                        this._fillData(cacheData);

                        this.whenLoadComplete(this._loadedData);

                        if (reload) {
                            setTimeout(() => {
                                if (req.filter == this._filter && req.filterArgs == this._filterArgs) {
                                    this.loadingCommandSink?.next(req);
                                }
                            }, BlocApiHelper.kCacheThenLoadDelay);
                        } else {
                            switch (this.cachePolicy) {
                                case BlocApiCachePolicy.CacheThenLoad:
                                    setTimeout(() => {
                                        if (req.filter == this._filter && req.filterArgs == this._filterArgs) {
                                            this.loadingCommandSink?.next(req);
                                        }
                                    }, BlocApiHelper.kCacheThenLoadDelay);

                                    break;

                                default:
                                    break;
                            }
                        }

                        return this._loadedData;
                    }
                } catch (e) {
                    Logger.error(e);
                    // throw e;
                }
            }
        }

        //endregion

        this._loadingOp = req.loadOp;

        // const reqUser = this._authService?.getUserID();

        try {
            const data = (await this.loadInternal(payload, filterArgs));

            // const user = this._authService?.getUserID();
            // check same user, otherwise return current _loadedData, without clearing it, because a subsequent call could have been already arrived
            // if (reqUser == user) 
            {
                // check same request
                if (reqId == this._loadReqId) {
                    this._loadedCache = false;
                    this._filter = req.filter;
                    this._filterArgs = req.filterArgs;

                    if (data != null) {
                        this._fillData(data);

                        const cacheable = await this.shouldWriteCache(data, payload, req);

                        if (cacheable) {
                            try {
                                await this.writeCache(this._loadedData, payload, req);
                            } catch (e) {
                                Logger.error(e);
                                // throw e;
                            }
                        }
                    } else {
                        this._clearData();
                    }
                } else {
                    this._clearData();
                }
            }

            this.whenLoadComplete(this._loadedData);
        } catch (error) {
            this.catchError(error);
        } finally {
            this._loadingOp = null;
        }

        return this._loadedData;
    }

    protected abstract loadInternal(payload: ApiLoadPayload, params: P | null): Promise<T | null>;

    public reload(reloadNow = false): void {
        if (this._loadDone || reloadNow) {
            const params = new BlocApiLoadParams<P>(
                BlocApiLoadOp.LoadAll,
                true,
                undefined, // user action
                this._filter,
                this._filterArgs
            );

            this.loadingCommandSink?.next(params);
        } else {
            this.setBookingReload(true);
        }
    }

    protected whenLoadComplete(data: T | null) {
    }

    //region reply

    // send last data again
    public reply(updated?: T | null, cache = false) {
        if (this._loadDone) {
            if (updated != null) this._fillData(updated);

            const params = new BlocApiLoadParams<P>(
                cache ? BlocApiLoadOp.ReplyAndCache : BlocApiLoadOp.Reply,
                true, // reload
                undefined, // user action
                this._filter,
                this._filterArgs
            );

            this.loadingCommandSink?.next(params);
        }
    }

    //endregion

    //region errors

    protected catchError(error: any): void {
        throw error;
    }

    //endregion  

    //region data

    protected _clearData(): void {
        this._loadDone = false;
        this._loadedData = null;
    }

    protected _fillData(data: T | null): void {
        this._loadDone = true;

        if (data != null) {
            this._loadedData = this.prepare(data);
          }
          else {
            this._loadedData = null;
          }
    }

    public prepare(data: T | null): T | null {
        // default implementation
        return data;
    }

    //endregion    

    //region cache

    protected async shouldCache(payload: ApiLoadPayload, params: BlocApiLoadParams<P>) : Promise<boolean> {
        const cacheAllowed = this.cachePolicy == BlocApiCachePolicy.OnlyCacheIfAny || this.cachePolicy == BlocApiCachePolicy.CacheThenLoad;
        if (cacheAllowed) {
            return (payload.filter == null || payload.filter.trim().length == 0);
        }

        return false;
    }    

    protected async shouldReadCache(payload: ApiLoadPayload, params: BlocApiLoadParams<P>): Promise<boolean> {
        return await this.shouldCache(payload, params);
    }

    protected async shouldWriteCache(data: T | null, payload: ApiLoadPayload, params: BlocApiLoadParams<P>) : Promise<boolean> {
        return await this.shouldCache(payload, params);
    }

    protected async readCache(payload: ApiLoadPayload, params: BlocApiLoadParams<P>): Promise<T | null> {
        // NOTE: override if needed
        return null;
    }

    protected async writeCache(data: T | null, payload: ApiLoadPayload, params: BlocApiLoadParams<P>): Promise<void> {
        // NOTE: override if needed
    }

    //endregion    
}

export default BlocApiSingle