import { IApiRepo } from "./_interfaces"
import { ApiProviderHttp } from "./apiProviderHttp";
import { ApiRepoHttp } from "./apiRepoHttp";
import { ApiRepoMock } from "./apiRepoMock";

export class ApiRepoFactory {
    static _theRepository: IApiRepo

    public static getRepo(): IApiRepo {
        return ApiRepoFactory._theRepository ??= 
        // new ApiRepoMock();
        new ApiRepoHttp(ApiProviderHttp.providerFactory());
    }
}
