import i18next from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Logger } from "../../../helpers/logger";
import IProduct from "../../../models/product";
import { ApiFilterArgsSingle, ProductBloc } from "../../../services/blocs/app/productBloc";
import { BlocApiLoadOp, BlocApiLoadParams } from "../../../services/blocs/_common";
import BaseSubject from "../../../subjects/baseSubject";
import { LoadingSubjectData } from "../../../subjects/loadingSubject";
import Scaffold from "./Scaffold";

interface ProductProps {

}

const Product: FunctionComponent<ProductProps> = () => {
    const params = useParams();
    const id = params.id as string;

    const [blocProduct] = useState<ProductBloc>(() => ProductBloc.blocFactory(id));
    const [loadingData, setLoadingData] = useState<LoadingSubjectData<IProduct | null> | null>(() => null);
    const [detailSelectedSubject] = useState<BaseSubject<number | null> | null>(() => new BaseSubject(null));

    useEffect(() => {
        const dataSubscription = blocProduct?.loadedStream?.subscribe((_) => {
            Logger.info(`ProductBloc data: `, _);
            setLoadingData(_);

            if (_ != null &&
              !_.loading && 
              !_.hasError) {
              const productFamily = _.data;

              if (productFamily == null) {
                detailSelectedSubject.add(null); 
              } else {
                const childId = productFamily.childIdDefault;

                if (childId != null) {
                  detailSelectedSubject.add(childId);
                }
              }
            }
          });
      
          const reload = false;
          const userAction = false;
          const filter: string = null;
          const filterArgs: ApiFilterArgsSingle = null;
      
          loadData(
            reload, 
            userAction, 
            filter, 
            filterArgs);
      
          return () => {
            dataSubscription?.unsubscribe();
            blocProduct?.dispose();
          };
    }, []);

    const loadData = async (reload: boolean, userAction: boolean, filter: string, filterArgs: ApiFilterArgsSingle = null): Promise<void> => {
        await blocProduct?.loadingCommandSink.next(new BlocApiLoadParams(
          BlocApiLoadOp.LoadAll, 
          reload, 
          userAction, 
          filter, 
          filterArgs,
          i18next.language
        ));
      }

    const refreshData = (): Promise<void> => {
        const reload = true;
        const userAction = true;
        const filter: string = null;
        const filterArgs: ApiFilterArgsSingle = null;

        return loadData(
            reload, 
            userAction, 
            filter, 
            filterArgs);
    }      

    return (
        <Scaffold
            id={id}
            loadData={refreshData}
            loadingData={loadingData} 
            detailSelectedSubject={detailSelectedSubject}
        />
    );
}

export default Product;