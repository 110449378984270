import { FunctionComponent, useState, useRef, Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import LayoutScaffold from "../../components/LayoutScaffold";
import Layout from "./Layout";

interface ScaffoldProps {
}

const Scaffold: FunctionComponent<ScaffoldProps> = (props) => {
  

  const [t, i18n] = useTranslation('common');
  const [tHome] = useTranslation('home');

  return (
    <LayoutScaffold
      canLogout={true}
      title={tHome('labels.title')}
      actions= {
          <Fragment>  
          </Fragment>
        }
      >
        <Layout />
    </LayoutScaffold>    
  );
}

export default Scaffold; 