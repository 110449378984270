import { shareLatest } from "@react-rxjs/core";
import { distinct, Observable, Subscriber } from "rxjs";

export class LoadingSubjectData<T> {
    readonly data: T | null
    readonly loading: boolean 

    readonly hasError: boolean
    readonly error: any 

    constructor (data: T | null, loading: boolean, hasError: boolean, error: any = null) {
        this.data = data;
        this.loading = loading
        this.error = error;
    }
}

class LoadingSubject<T> {
    private _dataLast: T | null = null
    private _isLoading = false

    private _hasError = false
    private _error: any

    public stream?: Observable<LoadingSubjectData<T>>;
    protected sink?: Subscriber<LoadingSubjectData<T>>;

    constructor (data: T | null, loading = false) {
        this._dataLast = data;
        this._isLoading = loading;
        const errorNone = this._hasError = false;
        const error: any = this._error = null;

        this.stream = new Observable<LoadingSubjectData<T>>((obs) => {
            this.sink = obs;
            if (data != null) {
                this.sink?.next(new LoadingSubjectData(this._dataLast, loading, errorNone, error));
            }
        }).pipe(shareLatest());
    }

    public loading(loading: boolean) : void {
        this._isLoading = loading;

        const errorNone = this._hasError = false;
        const error: any = this._error = null;

        this.sink?.next(new LoadingSubjectData(this._dataLast, loading, errorNone, error));
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public add(data: T | null, loading = false) : void {
        this._dataLast = data;
        this._isLoading = loading;

        const errorNone = this._hasError = false;
        const error: any = this._error = null;

        this.sink?.next(new LoadingSubjectData(data, loading, errorNone, error));
    }

    public get lastData(): T | null {
        return this._dataLast;
    }

    public addError(error: any, loading = false) : void {
        this._isLoading = loading;

        this._hasError = true;
        this._error = error;

        // HACK: an error will complete the observable
        // this.sink?.error(error);
        this.sink?.next(new LoadingSubjectData(this._dataLast, loading, true, error));
        // ]
    }

    public get hasError(): boolean {
        return this._hasError;
    }    

    public get getError(): any {
        return this._error;
    }

    public clear(): void {
        this.add(null, false);
    }

    public close(): void {
        this._isLoading = false;
        
        this._hasError = false;
        this._error = null;

        this.sink?.complete();
    }

    public isClosed(): boolean {
        return this.sink?.closed ?? true;
    }
}

export default LoadingSubject