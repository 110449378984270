import { callbackify } from "util";

export interface ILogFn {
  (message?: any, ...optionalParams: any[]): void;
}

export interface IConsoleLogger {
  log: ILogFn;
  info: ILogFn;
  warn: ILogFn;
  error: ILogFn;
}

export type LogLevel = 'log' | 'warn' | 'error';

const NO_OP: ILogFn = (message?: any, ...optionalParams: any[]) => {};

export class ConsoleLogger implements IConsoleLogger {
  readonly debug: ILogFn;
  readonly log: ILogFn;
  readonly info: ILogFn;
  readonly warn: ILogFn;
  readonly error: ILogFn;

  protected inGroup = false;

  private constructor(options?: { level? : LogLevel }) {
    const { level } = options || {};

    this.error = (...args) => {
      this.print('error', args);
    };

    if (level === 'error') {
      this.warn = NO_OP;
      this.info = NO_OP;
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }
    
    this.warn = (...args) => {
      this.print('warn', args);
    }

    if (level === 'warn') {
      this.info = NO_OP;
      this.log = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.info = (...args) => {
      this.print('info', args);
    }

    this.log = (...args) => {
      this.print('log', args);
    };

    this.debug = (...args) => {
      this.print('debug', args);
    };
  }

  private print(method: string, args: any) {
      if (self.__WB_DISABLE_DEV_LOGS) {
          return;
      }
      if (method === 'groupCollapsed') {
          // Safari doesn't print all console.groupCollapsed() arguments:
          // https://bugs.webkit.org/show_bug.cgi?id=182754
          if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              console[method](...args);
              return;
          }
      }

      let color: string | null = null;

      switch(method) {
        case 'debug': 
          color = `#7f8c8d`;
          break;
        case 'info': 
          color = `#2e71cc`;
          break;          
        case 'log': 
          color = `#2ecc71`;
          break;
        case 'warn': 
          color = `#f39c12`;
          break;
        case 'error': 
          color = `#c0392b`;
          break;
        case 'groupCollapsed': 
          color = `#3498db`;
          break;
        case 'groupEnd': 
        default:
          break;
      }

      const styles = [
          `background: ${color}`,
          `border-radius: 0.5em`,
          `color: white`,
          `font-weight: bold`,
          `padding: 2px 0.5em`,
      ];

      // When in a group, the workbox prefix is not displayed.
      const logPrefix = this.inGroup ? [] : ['%clog', styles.join(';')];

      switch(method) {
        case 'debug': 
          console.debug(...logPrefix, ...args);
          break;
        case 'log': 
          console.log(...logPrefix, ...args);
          break;
        case 'info': 
          console.info(...logPrefix, ...args);
          break;          
        case 'warn': 
          console.warn(...logPrefix, ...args);
          break;
        case 'error': 
          console.error(...logPrefix, ...args);
          break;
        case 'groupCollapsed': 
          console.groupCollapsed(...logPrefix, ...args);
          break;
        case 'groupEnd': 
          console.groupEnd();
          break;
        default:
          break;
      }      

      if (method === 'groupCollapsed') {
        this.inGroup = true;
      }
      if (method === 'groupEnd') {
        this.inGroup = false;
      }
  }

  static _logger: ConsoleLogger;

  static factory(): ConsoleLogger {
    // console.log(`ConsoleLogger level ${process.env.REACT_APP_LOG_LEVEL}`);
    return ConsoleLogger._logger ??= new ConsoleLogger({ level: (process.env.REACT_APP_LOG_LEVEL as LogLevel) ?? 'log' });
  }
}

export const Logger = ConsoleLogger.factory();