import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

interface LayoutProps {
    
}
 
const Layout: FunctionComponent<LayoutProps> = () => {
    return ( 
        <div>
        {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}

        {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
            <Outlet />
        </div>
    );
}
 
export default Layout;