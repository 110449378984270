import { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../core/router";
import { LOCAL_STORAGE } from "../../_constants";
import { INavigationItem } from "../../models/_interfaces";

interface LayoutScaffoldProps {
    canLogout: boolean
    title: string
    subtitle?: string
    navigation?: INavigationItem[]
    actions?: React.ReactNode
    children?: React.ReactNode
}

const LayoutScaffold: FunctionComponent<LayoutScaffoldProps> = (props) => {
    const { canLogout, title, subtitle, actions, navigation, children } = props;

    // const navigate = useNavigate();

    const [t, i18n] = useTranslation('common');
    const [tHome] = useTranslation('home');

    return (
        <>
            <div className="min-h-full">
                {/* <nav className="relative w-full flex flex-wrap items-center justify-between py-3 bg-gray-100 text-gray-500 hover:text-gray-700 focus:text-gray-700 shadow-lg navbar navbar-expand-lg navbar-light">
                    <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex items-start justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="h-6 w-6"
                                        src="/hero_pos.png"
                                        alt="logo" />
                                </div>
                            </div>
                            <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
                                <nav className="bg-grey-light rounded-md w-full" aria-label="breadcrumb">
                                <ol className="list-reset flex">
                                    <li>
                                        <Link to={ROUTES.HOME}>
                                            <span className="text-gray-500 hover:text-gray-600">{ t('menu.home') }</span>
                                        </Link>
                                    </li>
                                    {
                                        navigation?.map((_) => {
                                            const label = _.label;
                                            const route = _.route;

                                            return <>
                                                <li><span className="text-gray-500 mx-2">\</span></li>
                                                <li>
                                                    {
                                                        route != null ? 
                                                            <Link to={route}>
                                                                <span className="text-gray-500 hover:text-gray-600">{ label }</span>
                                                            </Link> : 
                                                                <span className="text-gray-500 hover:text-gray-600">{ label }</span>
                                                    }
                                                </li>
                                            </>;
                                        })
                                    }
                                </ol>
                                </nav>
                            </div>
                        </div>                                
                    </div>
                </nav> */}

                <main>
                    <div className="max-w-8xl mx-auto py-8 sm:px-6 lg:px-8">
                        {
                            children
                        }
                    </div>
                </main>
            </div>
        </>
    );
}

export default LayoutScaffold;