import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useNavigate, useRoutes } from "react-router-dom";
import './App.css';

// import { AuthService } from '../services/auth/authService';

import routes, { ROUTES } from './router';
import NoMatch from '../ui/pages/notFound/NotFound';
import ErrorBoundary from '../ui/components/ErrorBoundary';
import { Logger } from '../helpers/logger';

interface AppProps { }

const App: FunctionComponent<AppProps> = () => {

  const renderCount = useRef(0);

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    Logger.info(`APP render count ${renderCount.current}`);
  })

  // const { service } = useContext<AuthServiceContext>(AuthContext);
  // const serviceAuth = AuthService.serviceFactory();

  const routeResult = useRoutes(routes);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const subscriptionAuth = serviceAuth.streamAuthenticated?.subscribe((_) => {
  //     Logger.info(`APP streamAuthenticated subscription: ${_}`);

  //     const authenticated = _.data ?? false;

  //     if (!authenticated) {
  //       navigate(ROUTES.LOGIN, { replace: true });
  //     }
  //   })

  //   return () => {
  //     subscriptionAuth?.unsubscribe();
  //   };
  // }, []);

  return (
      <ErrorBoundary>
        <div className="App">
          {/* <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.tsx</code> and save to reload.
            </p>
            <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
              Learn React
            </a>
          </header> */}

          {routeResult || <NoMatch />}
        </div>
      </ErrorBoundary>
  );
}

//NOTE:! using any as i couldn't find out a way to use the correct type for setThemeName -> React.Dispatch<...
export const ThemeContext = React.createContext<any>(null);

export default App;