import React from "react";
import { WithTranslation, withTranslation } from 'react-i18next';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { Logger } from "../../helpers/logger";
import ErrorWidget from "./ErrorWidget";
import i18next from "i18next";

interface ErrorBoundaryProps extends WithTranslation {
    children?: React.ReactNode | React.ReactNode[];
}

interface ErrorBoundaryState {
    error?: any
    errorInfo?: any
    reactLifeCycle: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    private errorLast?: any
    private readonly kTimeoutDelay = 5000;
    private timeoutDismiss?: NodeJS.Timeout

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null, errorInfo: null, reactLifeCycle: false };
    }

    componentDidMount() {
        window.onunhandledrejection = (err: PromiseRejectionEvent) => {
            this.setState({
                error: err.reason,
                reactLifeCycle: false
            })            
        }
    }

    componentDidCatch(error: any, info: any) {
        // NOTE: only exception thrown during react components life cycle are captured here
        this.setState({
            error: error,
            errorInfo: info,
            reactLifeCycle: true
        })
    }

    resetError = () => {
        this.setState({
            error: null
        })
    }

    render() {
        const error = this.state.error;
        const isException = error != null;
        const isReactException = this.state.reactLifeCycle && isException; 
        
        const errorIsString = isException ? typeof error === 'string' || error instanceof String : false;
        const errorMessage = isException ? (errorIsString ? error : (error.message != null ? error.message : JSON.stringify(error))) : null;
        
        const genericMessage = this.props.t('labels.error_hint');
        const genericDetailsMessage = this.props.t('labels.error_generic');

        if (error != null && !isReactException) {
            if (error != this.errorLast) {
                clearTimeout(this.timeoutDismiss);
                this.timeoutDismiss = setTimeout(() => {
                    this.resetError();
                }, this.kTimeoutDelay);
            }
        } else {
            if (this.timeoutDismiss != null) {
                clearTimeout(this.timeoutDismiss);
            }
        }

        return (
            <div>
            {
                isReactException ?
                    <ErrorWidget 
                        errorCode={this.props.t('labels.error_code_generic')}
                        title={genericMessage}
                        subTitle={errorMessage ?? genericDetailsMessage}
                    /> 
                    :
                    <React.Fragment>
                        { this.props.children }
                        {
                            isException ?
                                <div className="bg-black absolute bottom-0 left-0 right-0">
                                    <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                                        <div className="flex items-center justify-between flex-wrap">
                                            <div className="w-0 flex-1 flex items-center">
                                                <span className="flex p-2 rounded-lg bg-black">
                                                <ExclamationIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </span>
                                                <p className="ml-3 font-medium text-white truncate">
                                                <span className="md:hidden">{genericMessage}</span>
                                                <span className="hidden md:inline">{errorMessage ?? genericMessage}</span>
                                                </p>
                                            </div>
                                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                                                <button
                                                    type="button"
                                                    className="-mr-1 flex p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                                                    onClick={this.resetError}>
                                                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                    </React.Fragment>
            }
            </div>
        );
    }
}

export default withTranslation('common')(ErrorBoundary);